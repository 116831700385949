.flexcenter {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: var(--2, #05aab0);
  cursor: pointer;
}

.flexcenter :hover {
  background: var(--2, #05969b);
}

.btntxt {
  color: #fff;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 94.118% */
}

.servicediv {
  padding: 35px 95px;
  gap: 50px;
}

.serviceheader {
  color: var(--2, #05aab0);
  text-align: center;
  font-family: Nunito Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.serviceblock {
  display: flex;
  padding: 2px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.serviceSubTxt {
  color: var(--4, rgba(0, 0, 0, 0.7));
  text-align: center;
  font-family: Nunito Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.animblock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;
}

.animtxt {
  display: flex;
  padding: 2px 0px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 15px;
  text-align: justify;
}

.serviceheaderfont {
  color: var(--2, #05aab0);
  text-align: center;
  font-family: Nunito Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.serviceheaderfont1 {
  color: var(--neutral-grey, #717171);
  font-family: Nunito Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 90.909% */
}

.serviceheaderfont2 {
  color: var(--neutral-grey, #717171);
  font-family: Nunito Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 133.333% */
}

.animation {
  height: 350px;

}



h5 {
  color: var(--neutral-grey, #717171);
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  /* 171.429% */
}

li {
  color: var(--neutral-grey, #717171);
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

ul {
  margin-top: -10px;
}

.demo-container {
  display: flex;
  padding: 70px 95px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: var(--1,
      linear-gradient(90deg,
        #d88813 -1.69%,
        #05aab0 101.12%,
        #05aab0 101.12%,
        #05aab0 101.12%));
}

.demo-txt {
  color: #fff;
  text-align: center;
  font-family: Nunito Sans;
  font-size: 42px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.dmoBtn {
  display: flex;
  padding: 20px 24px;
  align-items: flex-start;
  border-radius: 30px;
  background: #fff;
}

.dmo-txt {

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  /* 80% */
  background: var(--1,
      linear-gradient(90deg,
        #d88813 -1.69%,
        #05aab0 101.12%,
        #05aab0 101.12%,
        #05aab0 101.12%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-container {
  display: flex;
  padding: 70px;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 30px 240px;
  flex-wrap: wrap;
  text-align: justify;
}

.about-txt {
  color: var(--2, #05aab0);
  text-align: center;
  font-family: Nunito Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}

.helping {
  display: flex;
  height: 330px;
  padding: 70px;
  align-items: center;
  align-content: center;
  gap: 30px 240px;
  flex-shrink: 0;
  flex-wrap: wrap;
}

.txt-container {
  display: flex;
  padding: 35px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.helping-subtext {
  color: var(--text-gray-900, #18191f);
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
}

.local {
  color: var(--neutral-d-grey, #4d4d4d);
  font-family: Nunito Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  /* 122.222% */
  text-transform: capitalize;
}

.reinvent {
  color: #05aab0;
  font-family: Nunito Sans;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  text-transform: capitalize;
}

.booking-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  flex: 1 0 0;
}

.img-class {
  height: 48px;
  width: 48px;
}

.helping-text {
  color: var(--neutral-grey, #717171);
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.numbers {
  color: var(--neutral-d-grey, #4d4d4d);
  font-family: Nunito Sans;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
}

.displayflexcenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.margin-left-4 {
  margin-left: 10px;
}

.footer-container {
  display: flex;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 20px;
  padding-bottom: 20px;
  background: #f3f3f3;
  gap: 1.5%;

  /* align-items: flex-start;
  align-content: flex-start; 
  gap: 165px 400px;
  flex-wrap: wrap; 
  gap: 5%; */
}

.footer-logo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;

}

.logo {
  display: inline-block;
}

.logo-txt {
  font-size: 35px;
  font-weight: 500;
  margin-top: -11px;
  margin-left: 3px;
}

.copy-rights {
  color: var(--2, #05AAB0);
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */


}

/* .................................New CSS............................................. */

.header-div {
  position: fixed;
  z-index: 1000;
}

.fBlock {
  padding-left: 10%;
  padding-right: 10%;
  
}

.ul-list {
  width: 100%;
}

.service-ul {
  list-style: disc;
  margin-top: 1%;
  padding-left: 5%;

}

.foot-title div {
  font-size: 16px;
  font-weight: bold;
  margin-top: 5%;

}

.foot-title ul {
  margin-top: 10%;
}

.email-input {
  border: none;
  background-color: lightgrey;
  border-radius: 5px;
  height: 40px;
  margin-top: 15%;
  font-size: small;
  font-weight: normal;
  padding: 3%;
}

.social-icons {
  width: 100%;
  display: inline-flex;
  gap: 3%;

}

.social-icons div {
  background-color: lightgrey;
  padding: 1.5%;
  border-radius: 100%;
  font-size: small;
}



.location-cards {
  max-width: fit-content;
  max-height: fit-content;
  background-color: white;
  border-radius: 4%;
  border: 1px solid;
  border-color: grey;
}

.img-section {
  border-radius: 10px;
}

#office-img {
  padding: 5%;
  width: 300px;
}

#office-city {
  font-size: 25px;
  font-weight: 700;
  padding-left: 5%;
}

#office-address {
  font-size: 15px;
  font-weight: 300;
  padding-left: 5%;
  text-indent: 0;
}

#getlocation-btn {
  width: 100%;
  padding-left: 5%;
  padding-left: 5%;
  margin-bottom: 5%;
  background: var(--Orange, #EC8623);
  color: #fff;
}







.Demo-header {
  color: var(--2, #05aab0);
  text-align: center;
  font-family: Nunito Sans;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}


.Demo-txt {
  text-align: center;
}

.SheduleDemo {
  background-color: #fff;
  padding-top: 1%;
  padding-left: 2%;
  padding-bottom: 1%;
  margin-top: 1%;
  margin-right: 6%;
  margin-left: 6%;

}

.Demo-img {
  border-radius: 2%;
  margin-top: 1%;
  margin-bottom: 1%;
  padding-left: 3%;

}
.scheduleDemo-form {
  margin-top: 3%;
}


#standard-multiline-flexible {
  width: 750px;
  margin-top: 2%;
}

#standard-basic {
  width: 350px;
  margin-top: 4%;
}

.demo-btn {
  width: 25%;
  margin-top: 3%;
  float: right;
  margin-right: 5%;
}

/* for contact us */


.contact-title{
   font-size: 40px;
  font-weight: bolder;
  color:var(--2, #05AAB0);
  text-align: center;
}
.contact-subtitle{
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  color: #717171;
}
.contact-flex{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 500px;
  gap: 80px;
  background-image: linear-gradient(to right, #05AAB0, #FBE7D3);
  
  
}
.contact-cards{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 350px;
  background-color: white;
  border-radius: 5%;
}
#contact-img{
  width: 125px;
  height: 108px;
  padding: 0;
}
#contact-text{
  text-align: center;
  justify-content: center;
  font-size: 22px;
  font-weight: bolder;
  padding: 5%;
  color: #4D4D4D;
}
#contact-subtext{
  font-size: 18px;
  color: #717171;
}

/* ------------------------------Media Query 1------------------------ */
@media (max-width:800px){
  .header-div {
    position: fixed;
    z-index: 1000;
    width:90%;

  }
  .fBlock{
    width:1000px;
    
  }
}


/* ------------------------------Media Query 2------------------------ */