.header-div {
  min-height: 70px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  padding: 0px 5%;
  position: fixed;
}
.logo {
}
.navBtn {
  color: #161616;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  margin-right: 25px;
  cursor: pointer;
}
.flex-display {
  display: flex;
  justify-content: center;
  align-items: center;
}
.fBlock {
  display: flex;
  padding: 115px 69px;
  justify-content: center;
  align-items: center;
  align-content: center;
  align-self: stretch;
  flex-wrap: wrap;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(5, 170, 176, 0.1) 100%
  );
  padding-bottom: 80px;
}
.ftext {
  display: flex;

  flex-direction: column;
  justify-content: center;
  font-family: Nunito Sans;
  font-size: 54px;
  font-style: normal;
  font-weight: 800;
  line-height: 73.5px; /* 136.111% */
  background: var(
    --1,
    linear-gradient(
      90deg,
      #d88813 -1.69%,
      #05aab0 101.12%,
      #05aab0 101.12%,
      #05aab0 101.12%
    )
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.subtxt {
  color: rgba(0, 0, 0, 0.5);
  font-family: Nunito Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 10px;
}
.subtxt1 {
  color: rgba(0, 0, 0, 0.5);
  font-family: Nunito Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 10px;
}
.whyus{
padding: 4% 6%;
margin-top: -105px;

}
.whyuscomp{
padding: 3% 5%;
align-content: center;
flex-wrap: wrap;
border-radius: 40px;
background: var(--8, #CDEEEF);
}
.whyustxt{
  color: var(--2, #05AAB0);
font-family: Nunito Sans;
font-size: 40px;
font-style: normal;
font-weight: 800;
line-height: normal;
}
.uscard{
  display: flex;
width: 250px;
height: 250px;
padding: 24px 10px;
flex-direction: column;
align-items: center;
gap: 8px;
flex-shrink: 0;
border-radius: 8px;
background: #FFF;
box-shadow: 2px 3px 6px 0px rgba(5, 170, 176, 0.15);
}
.cardtxt{
  color: var(--neutral-d-grey, #4D4D4D);
text-align: center;
font-family: Nunito Sans;
font-size: 18px;
font-style: normal;
font-weight: 800;
line-height: 36px; /* 200% */
margin-top: 10px;
}
.cardstxt{
  color: var(--neutral-d-grey, #4D4D4D);
text-align: center;
font-family: Nunito Sans;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px; /* 200% */
margin-top: 10px;
}
.animation div{
  height: 350px;
}